import React from "react";
import PropTypes from "prop-types";
import {
  styled,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  Checkbox,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    border: 0,
    fontSize: 12,
    color: "#212B36",
    whiteSpace: "nowrap",
    backgroundColor: "#F4F6F8",
  },
  ["&:first-of-type"]: {
    borderRadius: "8px 0 0 8px",
  },
  ["&:last-child"]: {
    borderRadius: "0 8px 8px 0",
  },
}));

const CustomTableHead = (props) => {
  const {
    rowCount = 0,
    numSelected = 0,
    onSelectAllRows,
    data,
    order,
    orderBy,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event) => onSelectAllRows(event.target.checked)}
            />
          </TableCell>
        )}
        {data.map((item, index) => {
          const sx = item.sx ? { ...item.sx } : {};
          return (
            <StyledTableCell key={index} align={item.align || "left"}>
              {item.onSort ? (
                <TableSortLabel
                  hideSortIcon
                  active={orderBy === item.key}
                  direction={orderBy === item.key ? order : "asc"}
                  onClick={() => item.onSort(item.key)}
                  sx={{
                    textTransform: "capitalize",
                    whiteSpace: "nowrap",
                    ...sx,
                  }}
                >
                  {item.title}

                  {orderBy === item.key ? (
                    <Box sx={{ ...visuallyHidden }}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                item.title
              )}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

CustomTableHead.propTypes = {
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllRows: PropTypes.func,
  //
  data: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.string,
};

export default CustomTableHead;

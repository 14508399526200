import { BASE_URL } from "api/Env";

const validate = (x) => !x;

function getNullRenameToString(obj) {
  for (let propName in obj) {
    if (typeof obj[propName] === "object" && obj[propName]) {
      for (let propNameX in obj[propName]) {
        if (validate(obj[propName][propNameX])) {
          obj[propName][propNameX] = "";
        }
      }
    } else if (validate(obj[propName])) {
      obj[propName] = "";
    }
  }
  return obj;
}

const FilterData = (data) => {
  const siparis = getNullRenameToString(data);
  return {
    address: siparis.address.address,
    addressCode: siparis.address.code,
    //addressCountry: siparis.address.country,
    addressCountry: siparis.address.city,
    addressLat: siparis.address.latitude,
    addressLng: siparis.address.longitude,
    addressFirstName: siparis.address.first_name,
    addressLastName: siparis.address.last_name,
    addressEmail: siparis.address.email,
    addressGsmNumber: siparis.address.gsm_number,
    addressDistrict: siparis.address.city,
    addressCounty: siparis.address.county,
    // Gönderici bilgileri
    customerAddress: "", // siparis.customer.addresses // TODO array geliyor.
    customerFirstName: siparis.customer.first_name,
    customerLastName: siparis.customer.last_name,
    customerEmail: siparis.customer.email,
    customerGsmNumber: siparis.customer.gsm_number,
    customerCode: siparis.customer.code,
    //
    warehouseFirmaID: siparis.warehouse.firm_id,
    warehouseName: siparis.warehouse.firm_name,
    warehouseCode: siparis.warehouse.code,
    warehouseOid: siparis.warehouse.id,
    //
    assignedBranchID: siparis.assigned_branch.id,
    //
    id: siparis.id,
    deci: siparis.deci,
    barcode: siparis.barcode,
    quantity: siparis.quantity,
    createdAt: siparis.created_at,
    orderDate: siparis.order_date,
    orderCode: siparis.order_code,
    // Delivery
    deliveryDate: siparis.delivery_date,
    deliveryType: siparis.delivery_type,
    deliveryDescription: siparis.delivery_description,
    deliveryStatus: siparis.delivery_status,
    deliveryTo: siparis.delivery_to,
    //
    barcodeURL: `${BASE_URL}qrcode/?id=${siparis.id}`,
    //
    mockData: data,
  };
};

export default FilterData;

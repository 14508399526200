import { testReducer } from "./testReducer";

import { projectReducer } from "./projectReducer";
import { customerReducer, customerUpdateReducer } from "./customerReducer";

import { combineReducers } from "redux";
import { loginReducer } from "./loginReducer";
import { userReducer } from "./userReducer";
import { connectRouter } from "connected-react-router";
import { projectApplicationReducer } from "./projectApplicationReducer";
import { projectApplicationDetailReducer } from "./projectApplicationDetailReducer";
import { jobsReducer } from "./jobsReducer";
import { tableReducer } from "./tableDataReducer";
import { messagesReducer } from "./messagesReducer";
import { liveTrackingReducer } from "./liveTrackingReducer";
import { orderReducer } from "./orderReducer";
import { warehouseAllReducer } from "./warehouseAllReducer";
import { warehousesReducer } from "./warehousesReducer";
import { getUnplannedOrders } from "./unplannedOrdersReducer";
import { unplannedCheckReducer } from "./unplannedCheckReducer";
import { ungeocodedReducer } from "./ungeocodedReducer";
import { suggestionReducer } from "./suggestionReducer";
import { uploadExcel } from "./uploadExcelReducer";
import { deliveryReducer } from "./reportsReducer";
import { vehicleManagementReducer } from "./vehicleManagementReducer";
import { vehicleZoneReducer } from "./vehicleZoneReducer";

import { uploadFileReducer } from "./uploadFilesReducer";

import { vehicleProjectReducer } from "./vehicleProjectReducer";
import { customDialogReducer } from "./customDialogReducer";
import { vehicleModelsReducer } from "./vehicleModelsReducer";
import { vehicleCompanyReducer } from "./vehicleCompanyReducer";
import { vehicleDriversReducer } from "./vehicleDriversReducer";
import { vehicleStatusReducer } from "./vehicleStatusReducer";
import { driverManagementReducer } from "./driverManagementReducer";
import { inactiveDateReasonReducer } from "./inactiveDateReasonReducer";
import { driverVehiclesReducer } from "./driverVehiclesReducer";
import { customerUserPopupReducer } from "./customerUserPopupReducer";
import { vehicleUsageReportReducer } from "./vehicleUsageReportReducer";
import { liveTrackingHistoryReducer } from "./liveTrackingHistoryReducer";
import { debitReducer } from "./debitReducer";
import { customerWarehouseReducer } from "./customerWarehouseReducer";
import { branchReducer } from "./branchReducer";
import { branchAssignmentReducer } from "./branchAssignmentReducer";
import { branchDrawingReducer } from "./branchDrawingReducer";
import { driverRoleReducer } from "./driverRoleReducer";
import { vehicleBranchReducer } from "./vehicleBranchReducer";
import { activeWarehouseReducer } from "./activeWarehouseReducer";
import { loadingReducer } from "./loadingReducer";
//
import { dashboardFilterReducer } from "./dashboardFilter";
import { analyticsFilterRecuder } from "./analyticsFilterRecuder";

import history from "../history";

// const routesMap = {
//   LOGIN: '/',
//   CUSTOMER_MANAGEMENT: '/customer_management',
// }

const rootReducer = combineReducers({
  router: connectRouter(history),
  testReducer,
  loginReducer,
  customerReducer,
  userReducer,
  projectReducer,
  projectApplicationReducer,
  projectApplicationDetailReducer,
  tableReducer,
  jobsReducer,
  messagesReducer,
  liveTrackingReducer,
  orderReducer,
  warehouseAllReducer,
  warehousesReducer,
  unplannedCheckReducer,
  getUnplannedOrders,
  ungeocodedReducer,
  suggestionReducer,
  uploadExcel,
  deliveryReducer,
  vehicleManagementReducer,
  vehicleZoneReducer,
  uploadFileReducer,
  vehicleProjectReducer,
  customDialogReducer,
  vehicleModelsReducer,
  vehicleCompanyReducer,
  vehicleDriversReducer,
  vehicleStatusReducer,
  driverManagementReducer,
  inactiveDateReasonReducer,
  driverVehiclesReducer,
  customerUserPopupReducer,
  vehicleUsageReportReducer,
  liveTrackingHistoryReducer,
  debitReducer,
  customerWarehouseReducer,
  branchReducer,
  branchAssignmentReducer,
  branchDrawingReducer,
  driverRoleReducer,
  vehicleBranchReducer,
  activeWarehouseReducer,
  loadingReducer,
  // customerUpdateReducer
  // byId: customerUpdateReducer
  //
  dashboardFilterReducer,
  analyticsFilterRecuder,
});

/**
 * all reducers combined here if u want to wipe all redux data dispatch TYPES.USER_LOGOUT from somewhere
 * @param {*} state
 * @param {*} action if action type == TYPES.USER_LOGOUT wipe all redux data check logout function
 * @returns root reducers
 */
const appReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export default appReducer;

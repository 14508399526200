import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, TextField } from "@mui/material";
import { useSnackbar } from "notistack";

export default function AlertDialog({ data, handleClose, handleActive }) {
  const { enqueueSnackbar } = useSnackbar();
  const [description, setDescription] = React.useState("");

  const isActive = data.status === "ACTIVE";

  function onClickActive() {
    if (!description) {
      enqueueSnackbar("Lütfen açıklama alanını boş bırakmayınız.", {
        variant: "error",
      });
      return;
    }

    handleActive(data.id, description, isActive ? "PASSIVE" : "ACTIVE");
  }

  const disableButton = Boolean(!description);

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={handleClose}>
      <DialogTitle>{data.plaka}</DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ pt: 2 }}>
          <TextField
            rows={4}
            multiline
            fullWidth
            size="small"
            value={description}
            placeholder={`${
              isActive ? "Pasif" : "Aktif"
            } etme sebebini açıklayınız`}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}>
          Vazgeç
        </Button>
        <Button
          variant="contained"
          onClick={onClickActive}
          disabled={disableButton}
        >
          {isActive ? "Pasit Et" : "Aktif Et"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  body: {
    noMatch: "Üzgünüz, eşleşen kayıt bulunamadı",
    toolTip: "Çeşit",
    columnHeaderTooltip: (column) => `Şunun için sırala: ${column.label}`,
  },
  pagination: {
    next: "Sonraki Sayfa",
    previous: "Önceki Sayfa",
    rowsPerPage: "Sayfa başına satır:",
    displayRows: "/",
  },
  toolbar: {
    search: "Arama",
    downloadCsv: "İndir CSV",
    print: "Yazdır",
    viewColumns: "Sütunları Görüntüle",
    filterTable: "Filtre Tablosu",
  },
  filter: {
    all: "Tümü",
    title: "Filtre",
    reset: "Temizle",
  },
  viewColumns: {
    title: "Sütunları Göster",
    titleAria: "Tablo Sütunlarını Göster/Gizle",
  },
  selectedRows: {
    text: "satır(lar) seçildi",
    delete: "Seçimi Temizle",
    deleteAria: "Seçili Satırları Sil",
  },
};

import TYPES from "../types";

const initialState = { 
  data: ''
 };

export const vehicleManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_VEHICLE_LIST_ALL:
      return { ...state, data: action.payload }
    case TYPES.GET_VEHICLE_ADD:
      return { ...state, data: [...state.data.data, action.payload.data[0]] }
    case TYPES.VEHICLE_UPDATE:
      console.log("state", state);
        var id= action.payload.data[0].id; 
      return {
          data: state.data.data ?
          state.data.data.map(content => content.id == id ? action.payload.data[0] : content) :
          state.data.map(content => content.id == id ? action.payload.data[0] : content)
        }
    case TYPES.VEHICLE_STATUS:
      return {
        data: state.data.data ? 
        state.data.data.map(content => content.id == action.payload.data[0].id? action.payload.data[0] :content) :
        state.data.map(content => content.id == action.payload.data[0].id? action.payload.data[0] :content)
       }  
    case TYPES.DELETE_VEHICLE:
      return { data: state.data.data ?
                        state.data.data.filter(vehicle => vehicle.id != action.payload.data[0].id) :
                        state.data.filter(content => content.id != action.payload.data[0].id)
             }
    case TYPES.GET_VEHICLES_WITH_DATE:
      return { data: action.payload.data }
    default:
      return state
  }

};

import React from "react";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "components/chart";
import { Services } from "api/Services";
import moment from "moment";

const getDate = (i) => moment().subtract(i, "days").format("YYYY-MM-DD");
const getKeyValues = (data = [], key) => {
  return data.map((item) => {
    const newData = item.entity.data;
    if (newData.length > 0) {
      return newData[0][key];
    }
    return 0;
  });
};

// services
const services = new Services();

export default function AnalyticsWebsiteVisits({ keys }) {
  const [data, setData] = React.useState([]);

  // Created Dates
  const days = [...new Array(7)].map((e, i) => [getDate(i), getDate(i)]);
  const daysServices = days.map((item) => services.getDashboardCounts(item));

  const getData = async () => {
    try {
      const res = await Promise.all(daysServices);
      const branch_accepted = getKeyValues(res, "branch_accepted");
      const delivered = getKeyValues(res, "delivered");
      const not_delivered = getKeyValues(res, "not_delivered");
      const on_the_way_to_customer = getKeyValues(
        res,
        "on_the_way_to_customer"
      );
      const order_accepted = getKeyValues(res, "order_accepted");
      const order_received = getKeyValues(res, "order_received");
      const will_be_return = getKeyValues(res, "will_be_return");

      setData([
        {
          name: keys.branch_accepted.title,
          type: "line",
          data: branch_accepted,
        },
        {
          name: keys.delivered.title,
          type: "line",
          data: delivered,
        },
        {
          name: keys.not_delivered.title,
          type: "line",
          data: not_delivered,
        },
        {
          name: keys.on_the_way_to_customer.title,
          type: "line",
          data: on_the_way_to_customer,
        },
        {
          name: keys.order_accepted.title,
          type: "line",
          data: order_accepted,
        },
        {
          name: keys.order_received.title,
          type: "line",
          data: order_received,
        },
        {
          name: keys.will_be_return.title,
          type: "line",
          data: will_be_return,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: "14%" } },
    fill: { type: ["solid", "gradient", "solid"] },
    labels: [...days.map((i) => i[0])],
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="Haftalık Veri Analizi" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={data}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}

import React from "react";
import useTable, { getComparator } from "hooks/useTable";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import Item from "./Item";

const listFilterData = (data) => {
  return data.map((item) => {
    return {
      detailData: item,
      // Custom render item
      id: item.id,
      name: item.name || "",
      firma: item.firm_name || "",
      code: item.code || "",
      il: item.city || "",
      ilce: item.county || "",
      adres: item.address || "",
    };
  });
};

const TableList = ({ loading, data, handleDeleteItem, handleEditItem }) => {
  const tableData = listFilterData(data);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  // Table Head Data
  const tableHeadData = [
    { key: "firma", title: "Proje", onSort },
    { key: "name", title: "Depo", onSort },
    { key: "code", title: "Kod", onSort },
    { key: "adres", title: "Adres", onSort },
    { key: "ilce", title: "İlçe", onSort },
    { key: "il", title: "İl", onSort },
    { key: "filter", title: "" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Filter
    filterSearch,
    filterSearchKeys: ["firma", "name", "code", "adres", "il", "ilce"],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  return (
    <CustomTable
      {...{
        loading,
        // Filter
        filterHidden: true,
        filterSearch,
        filterSearchOnChange,
        filterSearchPlaceholder: "Aramak istediğiniz kelimeyi giriniz.",
        // Table
        tableHeadData,
        tableHeadDataOrder: order,
        tableHeadDataOrderBy: orderBy,
        tableData,
        tableDataFiltered,
        // UseTable
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        tableBodyRender: tableBodyRenderData.map((item, index) => {
          return (
            <Item
              key={`${item.id}-${index}`}
              item={item}
              handleEdit={handleEditItem}
              handleDelete={handleDeleteItem}
            />
          );
        }),
      }}
    />
  );
};

export default TableList;

import React, { useState, useEffect } from "react";
import Layout from "components/Layout";
import { getDispatcherPlannerLink } from "../../store/actions/dispatcherPlannerUrl";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { ArrowBack, Close } from "@mui/icons-material";

function DispatcherPlanner(props) {
  const history = useHistory();
  const [dispatcherUrl, setDispatcherUrl] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDispatcherPlannerLink(setDispatcherUrl));
  }, []);

  const iframeContentStyle = {
    // position: "fixed",
    // background: "#000",
    // border: "none",
    // top: 100, right: 0,
    // bottom: 0, left: 0,
    // width: "100%",
    // height: "100%"
    //height: 'calc(100vh - 180px)'
  };

  const iframeContentStyle1 = {
    // position: "absolute",
    // top: "0",
    // left: "0",
    // width: "100%",
    // height: "100%",
    // //background: "red",
    // paddingTop: "90px"
  };

  const iframeContentHead = {
    position: "absolute",
    top: "95px",
    marginLeft: "27px",
  };

  const iframeContentBackIcon = {
    position: "absolute",
    top: "95px",
    background: "#ea7972",
    borderRadius: "20px",
    color: "white",
    cursor: "Pointer",
    paddingTop: "7px",
    paddingLeft: "7px",
    width: "38px",
    zIndex: "999999999",
  };

  return (
    <Box sx={{ height: "100vh", overflow: "hidden" }}>
      <Stack direction="row" sx={{ height: 60, px: 2 }} alignItems="center">
        <Box flex={1}>
          <Box
            component="img"
            src="/hey-bringo-logo.png"
            sx={{ width: 140, height: 60, objectFit: "contain" }}
          />
        </Box>
        <IconButton color="inherit" onClick={history.goBack}>
          <Close />
        </IconButton>
      </Stack>
      <iframe
        src={dispatcherUrl}
        title="Dispatcher Live"
        style={{
          height: "calc(100vh - 60px)",
        }}
        //height="450px"
        width="100%"
        //style={{ "overflow": "hidden", "overflowX": "hidden", "overflowY": "hidden", "height": "100%", "width": "100%", "position": "absolute", "top": "100px", "left": "0px", "right": "0px", "bottom": "0px" }}
      ></iframe>
      {/* <div style={iframeContentStyle1}>x</div> */}
    </Box>
  );
}

export default DispatcherPlanner;

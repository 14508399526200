import PropTypes from "prop-types";
// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { InputLabel, OutlinedInput, Select, FormControl } from "@mui/material";

// ----------------------------------------------------------------------

RHFMultiSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFMultiSelect({ name, children, ...other }) {
  const { control } = useFormContext();
  console.log("RHFMultiSelect", name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl>
          <InputLabel
            id="demo-multiple-checkbox-label"
            sx={{
              overflow: "visible !important",
              lineHeight: "0.5rem !important",
            }}
          >
            {other.label}
          </InputLabel>
          <Select
            {...field}
            labelId="demo-multiple-checkbox-label"
            multiple
            fullWidth
            error={!!error}
            helperText={error?.message}
            SelectProps={{ native: true }}
            input={<OutlinedInput label={`${other.label}`} />}
            {...other}
          >
            {children}
          </Select>
        </FormControl>
      )}
    />
  );
}

import React from "react";
import moment from "moment";
import useTable, { getComparator } from "hooks/useTable";
import Layout from "components/Layout";
import { Services } from "api/Services";
import Label from "components/CustomTable/TableLabel";
import CustomTable from "components/CustomTable";
import { applySortFilter } from "components/CustomTable/utils";
import { Stack, TableCell, TableRow, Typography } from "@mui/material";
import DetailLink from "components/CustomTable/DetailLink";
import DownloadExcel from "components/CustomTable/DownloadExcel";
import AnalyticTools from "components/CustomTable/AnalyticTools";
import { getStatus } from "helper";
import createAvatar from "utils/createAvatar";
import Avatar from "components/CustomTable/Avatar";

const server = new Services();
// Created Dates
const prevMonthDate = moment().subtract(1, "months");
const currentDate = moment();
// Tabs
const tabs = [
  {
    value: "all",
    label: "Tümü",
    color: "info",
    key: "all",
  },
  {
    value: "ulasilamadi",
    label: "Teslim Edilemedi",
    color: "warning",
    key: "NOT_DELIVERED",
  },
  // {
  //   value: "subeyeIade",
  //   label: "Şubeye iade",
  //   color: "error",
  //   key: "BRANCH_RETURN",
  // },
  {
    value: "depodayok",
    label: "Depoda Yok",
    color: "error",
    key: "NOT_FOUND",
  },
  {
    value: "aracta",
    label: "Araçta",
    color: "success",
    key: "COURIER_DEBIT",
  },
];
// Filter Options Data
const filterOptions = [
  "Tümü",
  "Teslim edildi",
  "Dağıtımda",
  "Depoda",
  "Kısmen teslim edildi",
  "Teslim edilmedi",
  "Teslim ertelendi",
  "İade edilecek",
  "Şubeye İade Kabul Yapıldı",
  "Sipariş müşteriden alınamadı",
  "Paket Kabul Yapıldı",
  "Şube Kabul Yapıldı",
  "Paket Kabul Bekliyor",
];

const Distribution = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [tabsActive, tabsOnChange] = React.useState("all");
  const [filterOption, filterOptionsOnChange] = React.useState(
    filterOptions[0]
  );
  const [filterDate, filterDateOnChange] = React.useState([
    prevMonthDate,
    currentDate,
  ]);
  const [filterSearch, filterSearchOnChange] = React.useState("");

  const getTableData = async () => {
    setLoading(true);
    try {
      const res = await server.getDistribution(filterDate);
      const data = res.entity.data.map((item) => {
        return {
          //subeyeIade: item.branch_return_count,
          depodayok: item.notfound_count,
          aracta: item.courier_debit_count,
          ulasilamadi: item.not_delivered_count,
          toplam: item.total,
          // iptalEdildi: 0,
          // aractaYok: 0
          seferID: item.route_id,
          tarih: item.route_date,
          durum: getStatus(item.status),
          surucuBilgisi: item.driver_name,
          surucuUsername: item.driver_username,
          aracPlaka: item.vehicle_name,
        };
      });
      setTableData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // Get pagination props
  const {
    order,
    orderBy,
    onSort,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "tarih" });

  // Table Head Data
  const tableHeadData = [
    { key: "surucuBilgisi", title: "Sürücü Bilgisi" },
    { key: "surucuUsername", title: "Sürücü Kullanıcı Adı" },
    { key: "seferID", title: "Sefer ID" },
    { key: "tarih", title: "Tarih", onSort },
    { key: "durum", title: "Durum" },
    { key: "aracPlaka", title: "Araç Plakası" },
    { key: "ulasilamadi", title: "Teslim Edilemedi", align: "center" },
    //{ key: "subeyeIade", title: "Şubeye İade", align: "center" },
    { key: "depodayok", title: "Depoda Yok", align: "center" },
    { key: "aracta", title: "Araçta", align: "center" },
    { key: "toplam", title: "Toplam", align: "center" },
  ];

  const tableDataFiltered = applySortFilter({
    tableData,
    // Tabs
    tabsActive,
    // Filter
    filterOption,
    filterSearch,
    filterSearchKeys: ["seferID"],
    comparator: getComparator(order, orderBy),
  });

  // Get data filter pagination
  const tableBodyRenderData =
    filterSearch === ""
      ? tableDataFiltered.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableDataFiltered;

  React.useEffect(() => getTableData(), [filterDate]);

  return (
    <Layout
      {...props}
      heading="Kurye Zimmet"
      links={[{ name: "Genel", href: "/" }, { name: "Kurye Zimmet" }]}
      action={
        <DownloadExcel
          name="Kurye Zimmet"
          tableHeadData={tableHeadData}
          tableData={tableData}
        />
      }
    >
      <AnalyticTools tableData={tableData} tabs={tabs} />
      <CustomTable
        {...{
          loading,
          // Tabs
          tabs,
          tabsActive,
          tabsOnChange,
          // Filter
          filterOption,
          filterOptions,
          filterOptionsOnChange,
          filterOptionsPlaceholder: "Sefer Durumu",
          filterDate,
          filterDateOnChange,
          filterSearch,
          filterSearchOnChange,
          // Table
          tableHeadData,
          tableHeadDataOrder: order,
          tableHeadDataOrderBy: orderBy,
          tableData,
          tableDataFiltered,
          // UseTable
          page,
          rowsPerPage,
          onChangePage,
          onChangeRowsPerPage,
          tableBodyRender: tableBodyRenderData.map((item, index) => (
            <TableRow key={index} hover sx={{ "& td": { border: 0 } }}>
              <TableCell sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt={item.surucuBilgisi}
                  color={createAvatar(item.surucuBilgisi).color}
                  sx={{ mr: 2 }}
                >
                  {createAvatar(item.surucuBilgisi).name}
                </Avatar>
                <Stack>
                  <Typography variant="body2" noWrap>
                    {item.surucuBilgisi}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>{item.surucuUsername}</TableCell>
              <TableCell>{item.seferID}</TableCell>
              <TableCell>{moment(item.tarih).format("DD/MM/YYYY")}</TableCell>
              <TableCell>
                <Label
                  color={
                    (item.durum === "PLANNED" ? "warning" : "error") || "info"
                  }
                >
                  {item.durum}
                </Label>
              </TableCell>
              <TableCell>{item.aracPlaka}</TableCell>
              <TableCell align="center">
                <DetailLink
                  pathname="/distribution/detail"
                  count={item.ulasilamadi}
                  routeID={item.seferID}
                  routeKey="NOT_DELIVERED"
                />
              </TableCell>
              {/* <TableCell align="center">
                <DetailLink
                  pathname="/distribution/detail"
                  count={item.subeyeIade}
                  routeID={item.seferID}
                  routeKey="BRANCH_RETURN"
                />
              </TableCell> */}
              <TableCell align="center">
                <DetailLink
                  pathname="/distribution/detail"
                  count={item.depodayok}
                  routeID={item.seferID}
                  routeKey="NOT_FOUND"
                />
              </TableCell>
              <TableCell align="center">
                <DetailLink
                  pathname="/distribution/detail"
                  count={item.aracta}
                  routeID={item.seferID}
                  routeKey="COURIER_DEBIT"
                />
              </TableCell>
              <TableCell align="center">
                <DetailLink
                  pathname="/distribution/detail"
                  count={item.toplam}
                  routeID={item.seferID}
                />
              </TableCell>
            </TableRow>
          )),
        }}
      />
    </Layout>
  );
};

export default Distribution;

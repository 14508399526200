import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "../../components/Navbar";
import { Auth } from "../../api/Auth";
import { Label, Message, Segment, Table } from "semantic-ui-react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import {
  Form,
  Checkbox,
  Icon,
  Grid,
  TextArea,
  Button,
} from "semantic-ui-react";
import {
  getMessages,
  test,
  saveMessages,
} from "../../store/actions/messagesAction";
import { Services } from "../../api/Services";

// import { Popup } from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Layout from "components/Layout";

// import { customerList } from "../../store/actions/customerAction";

const MessageManagement = (props) => {
  // const [messageTable, setMessageTable] = useState();
  const [messagePreview, setMessagePreview] = useState();
  const [dialogPreview, setDialogPreview] = useState(false);
  const [dialogSymbol, setDialogSymbol] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState();
  const [dialogRowKey, setDialogRowKey] = useState(null);
  const [addSymbol, setAddSymbol] = useState(null);
  // const [allTEXTAREA, setAllTEXTAREA] = useState();
  const [selectionRange, setSelectionRange] = useState({
    selectionStart: null,
    selectionEnd: null,
  });
  const [selectionTextarea, setSelectionTextarea] = useState({
    selectionStart: null,
    selectionEnd: null,
    id: null,
    messages: null,
  });

  const toast = useRef(null);

  const dispatch = useDispatch();
  const getMessagesData = useSelector((state) => state.messagesReducer);
  const customerList = useSelector((state) => state.customerReducer);
  // const textareaRef = useRef();
  // const cursorPosition = 0;
  var customerId = props.location.search.substr(12, 100);

  useEffect(() => {
    dispatch(getMessages(customerId));
  }, []);

  var auth = new Auth();
  var server = new Services();
  var isAuthUser = auth.isAuthenticated();

  useEffect(() => {
    if (!isAuthUser) props.history.replace("/login");
  }, [isAuthUser]);

  /**
   * Checkbox
   */
  const handleClick = (e) => {
    dispatch(test({ id: e.target.id, statusChecked: e.target.checked }));

    let message = getMessagesData.data.filter(
      (message) => message.notification_type == e.target.id
    );

    server
      .notificationPreview(message[0].notification_message, customerId)
      .then((res) => {
        setMessagePreview(res.entity.data[0]);
        setDialogPreview(true);
      });
  };

  // const getDuplicate = inString => {
  //   const arr = inString.removeTurkishCharacters().replace(/(([{{}}]))+/g, '').split(/\W+/g);
  //   console.log(arr);
  //   let duplicated = arr.find( (word,index) =>
  //     arr.slice(0,index).includes(word)
  //   );

  //   return arr;
  // }

  /**
   * Update state on textarea value change
   */
  const handleChange = (e, i) => {
    const val = (e.target && e.target.value) || "";
    // var value;
    // if(selectedSymbol) value = e.target.value + selectedSymbol;
    // else value = e.target.value;

    // setSelectedSymbol(null);
    // setAddSymbol(null);

    // // formating last cursor position
    // // setSelectionRange({
    // //   selectionStart: null,
    // //   selectionEnd: null
    // // });

    // // console.log(getDuplicate(value));

    // let start = e.target.selectionStart;
    // let end = e.target.selectionEnd;

    // //const message = new Set(getDuplicate(value));

    dispatch(
      test({
        id: e.target.attributes[0].textContent,
        sms: handleLastKeyPosition(e) || e.target.value,
      })
    );
  };

  // const handleKeyDown = e => {
  //   if(selectedSymbol) {
  //     // e.preventDefault();
  //     console.log(e);
  //   }
  //   // console.log(e.target.selectionStart, e.key, selectedSymbol, e.key+selectedSymbol, );
  //   if(e.target.selectionStart && e.target.selectionEnd){  // Check if range exist
  //     setSelectionRange({
  //       selectionStart: e.target.selectionStart,
  //       selectionEnd: e.target.selectionEnd
  //     });
  //   }
  //   setAddSymbol(''); // reset the selected symbol
  // };

  /**
   * The onblur event gets fired
   * mouse position(start,end), text value etc. get the value
   */
  const handleLastKeyPosition = (e) => {
    let start = e.target.selectionStart;
    let end = e.target.selectionEnd;
    let id = e.target.attributes[0].value;
    let txMessages = e.target.value;

    //var new_value

    setSelectionRange({
      selectionStart: start,
      selectionEnd: end,
    });

    setSelectionTextarea({
      selectionStart: start,
      selectionEnd: end,
      id: id,
      messages: txMessages,
    });

    // if (addSymbol) new_value = e.target.value.substring(0, start) + addSymbol + e.target.value.substring(end);
    // else new_value = e.target.value;

    // if (textarea_ref && new_value) {
    //   textarea_ref.value = new_value;
    // }

    // return new_value;
  };

  // // Mousedown on event listener to get the current cursor position
  // useEffect(() => {
  //   let textarea = textareaRef.current && textareaRef.current.ref;

  //   let TEXTAREA = [];
  //   document.addEventListener("DOMContentLoaded", function(events) {
  //     // event.target.forms.forEach(textarea => {
  //     //   TEXTAREA.push(textarea[1]);
  //     // })
  //     var htmlCollection = events.target && events.target.forms; // get all available forms on DOM ready
  //     var allTextareas = Array.prototype.slice.call( htmlCollection ); // convert htmlCollection to an array

  //     for(const textarea of allTextareas){
  //         TEXTAREA.push(textarea[1]);
  //         textarea[1].addEventListener('click', event => {
  //           textarea[1].focus();
  //           // set the current cursor position
  //           // if(selectionRange.selectionStart !== null && selectionRange.selectionEnd !== null){
  //           setAddSymbol('');
  //           setSelectionRange({
  //               selectionStart: textarea[1].selectionStart,
  //               selectionEnd: textarea[1].selectionEnd
  //             })
  //             // }
  //         })
  //     }
  //     setAllTEXTAREA(TEXTAREA);
  //   });

  //   textarea && textarea.current.addEventListener('mousedown', event => {
  //     console.log(textarea.current.selectionStart, textarea.current.selectionEnd) // logs previous location
  //   })
  // }, [selectionRange]);

  // Get the selected symbol in a text format
  const handleSelectedSymbol = (select) => {
    const selected = select.target && select.target.innerHTML;

    setSelectedSymbol(selected);
    // setDialogSymbol(false);
  };

  /**
   * method adding symbol
   */
  const insertSymbolAtCursor = () => {
    let cc =
      selectionTextarea.messages.substring(
        0,
        selectionTextarea.selectionStart
      ) +
      " " +
      selectedSymbol +
      " " +
      selectionTextarea.messages.substring(
        selectionTextarea.selectionEnd,
        selectionTextarea.messages.length
      );
    console.log(cc);

    dispatch(test({ id: selectionTextarea.id, sms: cc }));

    // dispatch(
    //   test({ id: e.target.attributes[0].textContent, sms: handleLastKeyPosition(e) || e.target.value })
    // );

    // let textarea_ref = textareaRef.current;
    // var start = textarea_ref.ref.current.selectionStart;
    // var end = textarea_ref.ref.current.selectionEnd;
    // var start = selectionRange.selectionStart;
    // var end = selectionRange.selectionEnd;
    // var new_value;

    //// Insert selected symbol at the previous cursor position
    // if (addSymbol && start == end) new_value = col_message.substring(0, start) + addSymbol + " " + col_message.substring(end, col_message.length) + " ";
    // else new_value = textarea_ref.value;

    // textarea_ref.value = new_value;

    // return new_value;
  };

  const successMessages = () => {
    toast.current.show({
      severity: "success",
      //summary: t('successfull'),
      summary: "Başarılı",
      //detail: message ? message : "Updated",
      detail: "Güncellendi",
      life: 3000,
    });
  };

  const saveNotification = () => {
    var getMesssagesActive = getMessagesData.data.filter(
      (getMes) => getMes.active === true
    );
    //if (getMesssagesActive.length <= 3) { // message option limit
    dispatch(saveMessages(getMessagesData, successMessages, customerId));

    // formatting last selected symbol
    setAddSymbol(null);

    // formating last cursor position
    setSelectionRange({
      selectionStart: null,
      selectionEnd: null,
    });

    // } else {

    //   toast.current.show({
    //     severity: "warn",
    //     summary: "Uyarı",
    //     detail: "3 den fazla seçenek seçemezsiniz",
    //     life: 3000,
    //   });

    // }
  };

  let Sembol = [
    { sembolName: "{{ORDER_CODE}}", description: "Sipariş Kodu" },
    { sembolName: "{{OUTLET_CODE}}", description: "Nokta Kodu" },
    { sembolName: "{{QUANTITY}}", description: "Sipariş Miktarı" },
    { sembolName: "{{NAME}}", description: "Alıcı" },
    { sembolName: "{{RECIPIENT}}", description: "Teslim Alan" },
    { sembolName: "{{URL}}", description: "Web Linki" },
    { sembolName: "{{TAKEN_BY}}", description: "Gönderen" },
    {
      sembolName: "{{NOT_VISITED_REASON}}",
      description: "Teslim Edilememe Nedeni",
    },
    {
      sembolName: "{{DELIVERY_CODE}}",
      description: "Teslimat Kodu",
    },
    { sembolName: "{{TRACK_CODE}}", description: "Takip Kodu" },
    {
      sembolName: "{{DELIVERED_QUANTITY}}",
      description: "Teslim Edilen Sipariş Miktarı",
    },
  ];

  const SembolRow = Sembol.map((col, i) => {
    return (
      <Table.Row>
        <Table.Cell>{col.sembolName}</Table.Cell>
        <Table.Cell>{col.description}</Table.Cell>
      </Table.Row>
    );
  });

  const handleSymbolDialog = (key) => {
    setDialogSymbol(true);
    setDialogRowKey(key);
  };

  let tableSmsSendtime = [
    "ilk sipariş sisteme düştüğü zaman",
    "Sofor app üzerinden gün başlangıcı aldığı zaman",
    "Araç ilk rotaya bastığı zaman tüm müşterilere",
    "Araç ilk rotaya bastığı zaman tüm müşterilere",
    "Araç yaklaşınca xx dakika da",
    "Teslimat kapandığında",
    "",
    "",
    "",
    "",
    "Paket Kabul Yapıldığında",
  ];

  let tableSmsTitle = [
    "Teslimat Adresi Düzenleme",
    "Teslimat Adresi Onaylama",
    "Planlamaya Hazır",
    "Güne Başla",
    "Yeni Adres İçin Dağıtım Onay Mesajı",
    "Ertesi Gün Dağıtım Mesajı",
    "Teslimat Seçenekleri Düzenleme",
    "Araç Takip",
    "Sipariş Teslim Edilemedi",
    "Sipariş Teslim Edildi",
    "Paket Kabul Yapıldı",
  ];

  let tablePassiveField = [
    "Teslimat Adresi Düzenleme",
    "Teslimat Adresi Onaylama",
    "Planlamaya Hazır",
    "Yeni Adres İçin Dağıtım Onay Mesajı",
    "Ertesi Gün Dağıtım Mesajı",
    "Araç Takip",
  ];

  const disableField = (val) => {
    return val == "Teslimat Adresi Düzenleme" ||
      val == "Teslimat Adresi Onaylama" ||
      val == "Planlamaya Hazır" ||
      val == "Yeni Adres İçin Dağıtım Onay Mesajı" ||
      val == "Ertesi Gün Dağıtım Mesajı"
      ? true
      : false;
  };

  // const textareaValue = (col, i) => {
  //   if (addSymbol !== null && dialogRowKey == i) {
  //     // return col.notification_message + " " + addSymbol + " ";
  //     return insertSymbolAtCursor(col.notification_message); // bind incoming notification message with a selected symbol if any
  //   } else return col.notification_message;

  //   // return selectedSymbol !== null && dialogRowKey == i ? col.notification_message + selectedSymbol : col.notification_message
  // }

  // add a controller to add the symbol with an add button
  const addSymbolController = () => {
    if (selectedSymbol) {
      setAddSymbol(selectedSymbol);
      insertSymbolAtCursor();
      setDialogSymbol(false);
    } else alert("No symbol selected");
  };

  const addSymbolTextarea = () => {
    return (
      <React.Fragment>
        <Button onClick={() => addSymbolController()}>
          <Icon name="add" />
          Ekle
        </Button>
      </React.Fragment>
    );
  };

  // useEffect(() => {
  //   const tableRow =
  //     getMessagesData.length != 0 &&
  //     getMessagesData.data.map((col, i) => {
  //       return (
  //         <Table.Row key={i}>
  //           <Table.Cell>{tableSmsTitle[i]}</Table.Cell>
  //           <Table.Cell>
  //             <Form>
  //               <TextArea
  //                 aa={col.notification_type}
  //                 defaultValue={col.notification_message}
  //                 contenteditable="true"
  //                 rows="5"
  //                 onChange={(e) => handleChange(e)}
  //               ></TextArea>
  //             </Form>
  //           </Table.Cell>
  //           <Table.Cell>{tableSmsSendtime[i]}</Table.Cell>
  //           <Table.Cell>
  //             <Checkbox
  //               id={col.notification_type}
  //               onClick={handleClick}
  //               defaultChecked={col.active}
  //             />
  //           </Table.Cell>
  //         </Table.Row>
  //       );
  //     });
  // setMessageTable(tableRow);
  //}, [getMessagesData]);
  // }, []);

  return (
    <Layout>
      <NavBar />
      <Toast ref={toast} />
      {/* filters customer name */}
      <span
        style={{
          "margin-left": "22px",
          background: "#f9fafb",
          padding: "9px",
          position: "relative",
          top: "-23px",
          border: "1px solid rgba(34,36,38,.15)",
          "box-shadow": "none",
          "border-radius": "0.28571429rem",
        }}
      >
        {" "}
        Müşteri Adı :{" "}
        {customerList.data &&
          customerList.data.filter((a) => a.id === customerId)[0].name}
      </span>

      <div>
        {/* <Popup trigger={messagePreview && true} position="right center"> */}
        {/* <div> {messagePreview} </div> */}
        {/* </Popup> */}
        <Dialog
          visible={dialogPreview && true}
          style={{ width: "450px" }}
          // header="Message Preview"
          modal
          footer
          onHide={() => setDialogPreview(false)}
        >
          <div className="confirmation-content">
            <i
            // className="pi pi-exclamation-triangle p-mr-3"
            // style={{ fontSize: "2rem" }}
            />
            {messagePreview && <span>{messagePreview}</span>}
          </div>
        </Dialog>
        <Dialog
          visible={dialogSymbol}
          style={{ width: "450px" }}
          // header="Message Preview"
          modal
          footer={addSymbolTextarea()}
          onHide={() => setDialogSymbol(false)}
        >
          <Form>
            <Form.Field>
              Sectiginiz sembol açıklaması:{" "}
              {Sembol.map((symbol, i) => (
                <b>
                  {symbol.sembolName == selectedSymbol
                    ? symbol.description
                    : ""}
                </b>
              ))}
            </Form.Field>
            <Form.Field>
              {Sembol.map((symbol, i) => (
                <Grid>
                  <Grid.Row>
                    <Checkbox
                      radio
                      label={symbol.sembolName}
                      value={symbol.sembolName}
                      checked={selectedSymbol === symbol.sembolName}
                      onChange={(e) => handleSelectedSymbol(e)}
                    />
                  </Grid.Row>
                </Grid>
              ))}
            </Form.Field>
          </Form>
        </Dialog>
        <Grid columns={4}>
          <Grid.Column widescreen={4}>
            <Table collapsing style={{ marginLeft: "20px" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Sembol</Table.HeaderCell>
                  <Table.HeaderCell>Açıklama</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>{SembolRow}</Table.Body>
            </Table>
            <div style={{ marginLeft: "20px" }}>
              <Segment>
                <Label as="a" color="red" ribbon>
                  Not:
                </Label>
                <Message floating>
                  Mesajlardaki karakter sınırı, değişkenlik gösterebilen tüm
                  unsurlar (Sipariş kodu, vs.) dahil 160 karakterdir. 160'ın
                  üstündeki SMS'ler daha geç iletilebilir.
                </Message>
              </Segment>
            </div>
          </Grid.Column>
          <Grid.Column widescreen={12}>
            <Table stackable collapsing style={{ marginRight: "20px" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>SMS Tipi</Table.HeaderCell>
                  <Table.HeaderCell width={5}>Özel SMS</Table.HeaderCell>
                  <Table.HeaderCell>SMS Gönderim Zamanı</Table.HeaderCell>
                  <Table.HeaderCell>Tercih</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {getMessagesData.length != 0 &&
                  getMessagesData.data.map((col, i) => {
                    return (
                      <Table.Row
                        key={i}
                        style={
                          disableField(tableSmsTitle[i])
                            ? { color: "#cecdcd" }
                            : {}
                        }
                      >
                        <Table.Cell>{tableSmsTitle[i]}</Table.Cell>
                        <Table.Cell>
                          <Form>
                            <Button
                              floated="right"
                              labelPosition="left"
                              color=""
                              size="medium"
                              style={{ background: "transparent" }}
                              onClick={(e) => handleSymbolDialog(i)}
                            >
                              <Icon name="edit" />
                              Sembol ekleyiniz
                            </Button>
                            <TextArea
                              // id={i}
                              aa={col.notification_type}
                              // value={ selectedSymbol !== null && dialogRowKey == i ? col.notification_message + " " + selectedSymbol + " " : col.notification_message}
                              //value={textareaValue(col, i)}
                              value={col.notification_message}
                              //onKeyDown = {e => handleKeyDown(e)}
                              //ref={textareaRef}
                              id={i}
                              key={i}
                              onBlur={(e) => handleLastKeyPosition(e)}
                              contenteditable="true"
                              rows="5"
                              onChange={(e) => handleChange(e)}
                              style={
                                disableField(tableSmsTitle[i])
                                  ? { color: "#cecdcd" }
                                  : {}
                              }
                              disabled={
                                disableField(tableSmsTitle[i]) ? true : false
                              }
                            ></TextArea>
                          </Form>
                        </Table.Cell>

                        <Table.Cell>{tableSmsSendtime[i]}</Table.Cell>
                        <Table.Cell>
                          <Checkbox
                            id={col.notification_type}
                            onClick={handleClick}
                            defaultChecked={col.active}
                            disabled={
                              tableSmsTitle[i] == "Teslimat Adresi Düzenleme" ||
                              tableSmsTitle[i] == "Teslimat Adresi Onaylama" ||
                              tableSmsTitle[i] == "Planlamaya Hazır" ||
                              tableSmsTitle[i] ==
                                "Yeni Adres İçin Dağıtım Onay Mesajı" ||
                              tableSmsTitle[i] == "Ertesi Gün Dağıtım Mesajı"
                                ? true
                                : false
                            }
                            // text={col.notification_message}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell colSpan="4">
                    <Button
                      floated="right"
                      icon
                      labelPosition="left"
                      color="green"
                      size="small"
                      onClick={saveNotification}
                    >
                      <Icon name="save" /> Kaydet
                    </Button>
                    <Button
                      floated="right"
                      icon
                      labelPosition="left"
                      color="orange"
                      size="small"
                    >
                      <Icon name="cancel" /> Vazgeç
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid>
      </div>
    </Layout>
  );
};

MessageManagement.whyDidYouRender = false;

export default MessageManagement;

import { matchPath } from "react-router";

// ----------------------------------------------------------------------

export { default as NavSectionVertical } from "./vertical";

export function isExternalLink(path) {
  return path.includes("http");
}

export function getActive(path, pathname) {
  return path
    ? !!matchPath(path, { path: pathname, exact: true, strict: false })
    : false;
}

import React from "react";
import PropTypes from "prop-types";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router";
import qs from "query-string";

AnalyticsTotalBox.propTypes = {
  item: PropTypes.object,
};

export default function AnalyticsTotalBox(props) {
  const history = useHistory();
  const { title, packageCount, orderCount, icon, status, in_route, hash } =
    props.item;

  function handleClick() {
    history.push({
      pathname: "/shipments-detail",
      search: qs.stringify({
        status,
        in_route,
        pageTitle: title,
      }),
      hash: hash ?? "#fromAnalytics",
    });
  }

  return (
    <Card
      sx={{
        flex: 1,
        p: 2,
        cursor: "pointer",
        transition: ".4s ease",
        "&:hover": {
          transform: "scale(1.01)",
        },
      }}
      onClick={handleClick}
    >
      <Stack direction="row" alignItems="center">
        <Stack spacing={1} flex={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: "600!important" }}>
            {title}
          </Typography>
          <Stack direction="row" alignItems="flex-end" spacing={0.2}>
            <Typography variant="h3" sx={{ fontWeight: "bold!important" }}>
              {packageCount}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ opacity: 0.75, fontWeight: "500!important", pb: 1 }}
            >
              /{orderCount}
            </Typography>
          </Stack>
          <Typography variant="caption" sx={{ opacity: 0.75 }}>
            Paket/Sipariş
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: 92, height: 92 }}
        >
          <Box
            src={icon}
            component="img"
            sx={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Stack>
      </Stack>
    </Card>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { OverlayView } from "@react-google-maps/api";
import {
  Divider,
  Fade,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";

GerceklesenMarker.propTypes = {
  route: PropTypes.object,
  location: PropTypes.object,
  item: PropTypes.object,
};

export default function GerceklesenMarker(props) {
  const { palette } = useTheme();
  const { route, item, location } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const fullName = `${route.driver.first_name} ${route.driver.last_name}`;

  return (
    <OverlayView
      bounds={{ ne: location, sw: location }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
    >
      <Stack
        onMouseEnter={handleClick()}
        onMouseLeave={() => setOpen(false)}
        sx={{
          width: 16,
          height: 16,
          cursor: "pointer",
          textAlign: "center",
          borderRadius: "50%",
          position: "relative",
          backgroundColor: palette.primary.light,
          border: "2px solid",
          borderColor: palette.primary.dark,
          zIndex: open ? 12 : 1,
        }}
      >
        <Popper
          open={open}
          transition
          placement="top"
          anchorEl={anchorEl}
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper sx={{ px: 2, py: 1, minWidth: 250 }}>
                <Typography variant="subtitle2" flex={1} textAlign="left">
                  Sürüş Bilgisi
                </Typography>
                <Divider />
                <Stack spacing={1} pt={1}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Plaka:
                    </Typography>
                    <Typography variant="caption">
                      {route.vehicle.name}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Sürücü:
                    </Typography>
                    <Typography variant="caption">{fullName}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Hız:
                    </Typography>
                    <Typography variant="caption">{item.speed}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Tarih:
                    </Typography>
                    <Typography variant="caption">
                      {moment(item.timestamp).format("DD.MM.YYYY")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography noWrap variant="caption">
                      Saat:
                    </Typography>
                    <Typography variant="caption">
                      {moment(item.timestamp).format("HH:mm:ss")}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Stack>
    </OverlayView>
  );
}

import React, { useState, useEffect } from "react";
import { Field, useField } from "formik";
import {
  FormHelperText,
  styled,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import UploadField from "./UploadField";
import Thumb from "./Thumb";
import Iconify from "components/Iconify";

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

const ImageForm = (props) => {
  const { palette } = useTheme();
  const {
    formField: { image },
    sx,
    ...other
  } = props;

  const [field, meta, helper] = useField(image.name);

  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;

  const [fileName, setFileName] = useState(value.name);

  const preview = value && typeof value === "string";

  const [file, setFile] = useState(!preview ? value.file : { result: value });
  const [src, setSrc] = useState(!preview ? value.src : { result: value });
  const _onChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => setFileName(file.name);
      if (file.name !== fileName) {
        reader.readAsDataURL(file);
        setSrc(reader);
        setFile(file);
      }
    }
  };

  useEffect(() => {
    if (file && fileName && src) {
      setValue({ file: file, src: src, name: fileName });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, fileName, file]);

  let isRequired = other.required;

  if (file && src && isRequired) {
    isRequired = false;
  }

  return (
    <Box sx={{ ...sx }}>
      <Box
        sx={{
          p: 1,
          position: "relative",
          overflow: "hidden",
          width: 120,
          height: 120,
          borderRadius: 100,
          m: "auto",
          bgcolor: "#FFFFFF",
          border: `1px dashed ${palette.grey[500_32]}`,
        }}
      >
        <Box
          sx={{
            width: 1,
            height: 1,
            borderRadius: 50,
            position: "relative",
            overflow: "hidden",
          }}
        >
          {file && src && <Thumb file={file} src={src}></Thumb>}
          <Box
            sx={{
              opacity: 0,
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
              zIndex: 3,
              "&:hover + div": {
                opacity: 1,
              },
            }}
          >
            <Field
              {...other}
              variant="outlined"
              field={field}
              component={UploadField}
              onChange={_onChange}
              isError={isError}
              required={isRequired}
              type="file"
              accept="image/*"
            />
          </Box>
          <PlaceholderStyle
            className="placeholder"
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 3,
              pointerEvents: "none",
              backgroundColor: palette.background.neutral,
              ...(file && {
                backgroundColor: "rgba(0,0,0,0.1)",
                opacity: 0,
                color: "common.white",
                "&:hover": { opacity: 0.72 },
              }),
            }}
          >
            <Iconify
              icon={"ic:round-add-a-photo"}
              sx={{ width: 24, height: 24, mb: 1 }}
            />
            <Typography variant="caption">
              {file ? "Resmi güncelle" : "Resim yükle"}
            </Typography>
          </PlaceholderStyle>
        </Box>

        {isError && <FormHelperText color={"red"}>{error}</FormHelperText>}
      </Box>
      <Typography
        variant="caption"
        sx={{
          mt: 2,
          mx: "auto",
          display: "block",
          textAlign: "center",
          color: "text.secondary",
        }}
      >
        İzin verilen *.jpeg, *.jpg, *.png
      </Typography>
    </Box>
  );
};

export default ImageForm;

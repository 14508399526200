import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Card, Typography, Box, Stack } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 3),
}));

// ----------------------------------------------------------------------

BookingWidgetSummary.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
  subTitle: PropTypes.string,
  subTotal: PropTypes.number,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};

export default function BookingWidgetSummary({
  title,
  packageCount,
  icon,
  onClick,
  sx,
}) {
  return (
    <RootStyle onClick={onClick} sx={sx}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box flex={1}>
          <Box>
            <Typography variant="h2">{packageCount}</Typography>
            <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
              {title}
            </Typography>
          </Box>
        </Box>
        <Stack
          sx={{
            width: 120,
            height: 120,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Stack>
      </Stack>
    </RootStyle>
  );
}

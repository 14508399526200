import React from "react";
import { Grid, Stack } from "@mui/material";
import { Box } from "@mui/system";
import Projects from "./Projects";
import ProjectRanking from "./ProjectRanking";
import VehicleTypes from "./VehicleTypes";
import VehicleStatics from "./VehicleStatics";
import AppCurrentDownload from "screens/Analytics/StaticsChart";
import AnalyticsTotalBox from "components/AnalyticsTotalBox";

const keys = {
  total_package: {
    title: "Toplam Paket Sayısı",
    orderTitle: "Toplam Sipariş Sayısı",
    color: "violet",
    img: "/images/customerDashboard/toplam-paket.svg",
  },
  delivered: {
    title: "Teslim edilen paket",
    orderTitle: "Teslim edilen sipariş",
    color: "blue",
    img: "/images/customerDashboard/teslim-edilen.svg",
  },
  not_delivered: {
    title: "Teslim edilmeyen paket",
    orderTitle: "Teslim edilmeyen sipariş",
    color: "green",
    img: "/images/customerDashboard/teslim-edilmeyen.svg",
  },
  on_the_vehicle: {
    title: "Araçta paket",
    orderTitle: "Araçta sipariş",
    color: "yellow",
    img: "/images/customerDashboard/aracta.svg",
  },
};

const AnalyticalNew = ({ distributions, statsData }) => {
  const chartData = [
    {
      name: "Teslim edilen paket",
      value: distributions?.delivered_package_count || 0,
    },
    {
      name: "Teslim edilmeyen paket",
      value: distributions?.not_delivered_package_count || 0,
    },
    {
      name: "Araçta paket",
      value: distributions?.on_board_package_count || 0,
    },
  ];
  const statistics = statsData.metrics[0];
  const vehicleTypes = statsData.types;

  const chartDataValues = chartData.map((item) => Number(item.value));
  const chartDataNames = chartData.map((item) => item.name);

  const projects =
    distributions.projects
      .map((item) => {
        if (!item.project_id) {
          return null;
        }
        return {
          id: item.project_id,
          name: item.project_name,
          value: item.package_count,
          order_count: item.order_count,
          deci: item.totaldeci,
          customer_logo: item.customer_logo,
        };
      })
      .filter(Boolean) || [];

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <Stack spacing={3}>
            <AppCurrentDownload
              data={chartDataValues}
              labels={chartDataNames}
            />
            <VehicleTypes data={vehicleTypes} />
            <VehicleStatics data={statistics} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Stack spacing={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <AnalyticsTotalBox
                  item={{
                    title: keys.total_package.title,
                    packageCount: distributions.package_count,
                    orderCount: distributions.order_count,
                    icon: keys.total_package.img,
                    in_route: true,
                    hash: "#fromDashboard",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <AnalyticsTotalBox
                  item={{
                    title: keys.delivered.title,
                    packageCount: distributions.delivered_package_count,
                    orderCount: distributions.delivered_order_count,
                    icon: keys.delivered.img,
                    in_route: true,
                    status: "DELIVERED",
                    hash: "#fromDashboard",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <AnalyticsTotalBox
                  item={{
                    title: keys.not_delivered.title,
                    packageCount: distributions.not_delivered_package_count,
                    orderCount: distributions.not_delivered_order_count,
                    icon: keys.not_delivered.img,
                    in_route: true,
                    status: "NOT_DELIVERED",
                    hash: "#fromDashboard",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <AnalyticsTotalBox
                  item={{
                    title: keys.on_the_vehicle.title,
                    packageCount: distributions.on_board_package_count,
                    orderCount: distributions.on_board_order_count,
                    icon: keys.on_the_vehicle.img,
                    in_route: true,
                    status: "ON_BOARD",
                    hash: "#fromDashboard",
                  }}
                />
              </Grid>
            </Grid>
            <Projects data={projects} />
            <ProjectRanking data={projects} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticalNew;

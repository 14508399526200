import React, { useState } from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";
// import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import L from "leaflet";
import { useSnackbar } from "notistack";
import "leaflet-control-geocoder";
import { Services } from "api/Services";
import {
  Box,
  InputAdornment,
  OutlinedInput,
  styled,
  TableBody,
  TableCell,
  Stack,
  TableContainer,
  Table,
  TableRow,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";

const plc = "Aramak istediğiniz adresi yazınız";

const service = new Services();

const searchBoxStyle = {
  position: "absolute",
  py: 2,
  left: 0,
  top: 0,
  right: 0,
  width: "100%",
  maxWidth: {
    xs: "280px",
    md: "445px",
  },
  zIndex: 9999,
  margin: "auto",
  borderRadius: 1,
};
const Listbox = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  height: 200,
  overflow: "auto",
  borderRadius: "0 0 8px 8px",
  backgroundColor: theme.palette.background.paper,
}));

const zoom = 18;
const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
});
const geocoder = L.Control.Geocoder.nominatim();

function Map({ coords, onChange }) {
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [map, setMap] = useState(null);
  const markerRef = React.useRef(null);
  const [search, setSearch] = React.useState("");
  const [placeholder, setPlaceholder] = React.useState(plc);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);
  const searchRef = React.useRef();

  // function LeafletgeoSearch() {
  //   useEffect(() => {
  //     const provider = new OpenStreetMapProvider();

  //     const searchControl = new GeoSearchControl({
  //       provider,
  //       marker: { icon },
  //       style: "bar",
  //       showMarker: false, // optional: true|false  - default true
  //       showPopup: false, // optional: true|false  - default false
  //       popupFormat: ({ result }) => {
  //         onChange({
  //           name: result.label,
  //           center: {
  //             lat: result.raw.lat,
  //             lng: result.raw.lon,
  //           },
  //         });
  //         map?.setView([result.raw.lat, result.raw.lon], zoom);
  //         enqueueSnackbar(result.label, { variant: "success" });
  //       },
  //       resultFormat: ({ result }) => result.label, // optional: function    - default returns result label
  //       maxMarkers: 1, // optional: number      - default 1
  //       retainZoomLevel: false, // optional: true|false  - default false
  //       animateZoom: true, // optional: true|false  - default true
  //       autoClose: false, // optional: true|false  - default false
  //       searchLabel: "Adresi giriniz", // optional: string      - default 'Enter address'
  //       keepResult: false, // optional: true|false  - default false
  //       updateMap: true, // optional: true|false  - default true
  //     });

  //     map.addControl(searchControl);

  //     return () => map.removeControl(searchControl);
  //     // eslint-disable-next-line
  //   }, []);

  //   return null;
  // }

  const onSearch = (val) => {
    setSearch(val);

    if (val === "") {
      setList([]);
      return;
    }

    if (searchRef.current) {
      clearTimeout(searchRef.current);
    }

    setLoading(true);

    searchRef.current = setTimeout(async () => {
      try {
        const res = await service.getGeocodeSuggestionList(val);
        setList(res.entity.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }, 400);
  };

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  const handleOnChange = (item) => {
    setPlaceholder(item.address);
    setSearch("");
    setList([]);
    onChange({
      name: item.address,
      center: {
        lat: item.latitude,
        lng: item.longitude,
      },
    });
  };

  const isList = list.length > 0;

  return coords ? (
    <>
      <MapContainer
        dragging
        zoom={zoom}
        center={coords}
        whenCreated={setMap}
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ChangeView center={coords || [0, 0]} zoom={zoom} />
        {map && coords && (
          <Marker
            draggable
            icon={icon}
            ref={markerRef}
            position={coords}
            eventHandlers={{
              dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                  geocoder.reverse(marker.getLatLng(), 20, (results) => {
                    const result = results[0];
                    onChange(result);
                    map?.setView(
                      [marker.getLatLng().lat, marker.getLatLng().lng],
                      zoom
                    );
                    enqueueSnackbar(result.name, { variant: "success" });
                  });
                }
              },
            }}
          />
        )}
        {/* {map && <LeafletgeoSearch />} */}
      </MapContainer>
      <Box sx={searchBoxStyle}>
        <OutlinedInput
          fullWidth
          size="small"
          value={search}
          sx={{
            bgcolor: "white",
            borderRadius: search === "" ? "8px" : "8px 8px 0 0",
            border: "0!important",
            "& > *": {
              border: "0!important",
            },
          }}
          placeholder={placeholder}
          onChange={(e) => onSearch(e.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
        {loading ? (
          <Stack
            alignItems="center"
            sx={{ py: 2, bgcolor: "white", borderRadius: "0 0 8px 8px" }}
          >
            <CircularProgress size={20} />
          </Stack>
        ) : (
          <>
            {isList ? (
              <Listbox>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      {list.map((item, index) => (
                        <TableRow
                          hover
                          key={index}
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleOnChange(item)}
                        >
                          <TableCell>
                            <Typography variant="caption">
                              {item.address}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Listbox>
            ) : search !== "" ? (
              <Box
                sx={{
                  p: 2,
                  bgcolor: "white",
                  textAlign: "center",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography color={palette.text.secondary} variant="subtitle2">
                  Hiç veri bulunamadı.
                </Typography>
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </>
  ) : null;
}

export default Map;

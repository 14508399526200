import React from "react";
import PropTypes from "prop-types";
import { OverlayView } from "@react-google-maps/api";
import { Stack } from "@mui/material";
import { Warehouse } from "@mui/icons-material";

DepoMarker.propTypes = {
  data: PropTypes.object,
  zIndex: PropTypes.number,
};

export default function DepoMarker(props) {
  const { data, zIndex } = props;

  return (
    <OverlayView
      zIndex={zIndex}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      bounds={{
        ne: { lat: data.latitude, lng: data.longitude },
        sw: { lat: data.latitude, lng: data.longitude },
      }}
    >
      <Stack
        sx={{
          position: "relative",
          width: 30,
          height: 30,
          bgcolor: "#FF0000",
          textAlign: "center",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          zIndex,
        }}
      >
        <Warehouse fontSize="small" sx={{ color: "white!important" }} />
      </Stack>
    </OverlayView>
  );
}

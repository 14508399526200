import PropTypes from "prop-types";
// @mui
import {
  Card,
  CardHeader,
  Typography,
  Stack,
  LinearProgress,
} from "@mui/material";
import { fPercent } from "utils/formatNumber";

// ----------------------------------------------------------------------

export default function AllShipmentAcceptances({ label, data }) {
  return (
    <Card>
      <CardHeader title={label} />
      <Stack spacing={8} sx={{ px: 3, py: 5.3 }}>
        {data.map((progress, i) => {
          return (
            <ProgressItem
              key={`${progress.text}-${i}`}
              progress={progress}
              onClick={progress.onClick}
            />
          );
        })}
      </Stack>
    </Card>
  );
}

// ----------------------------------------------------------------------

ProgressItem.propTypes = {
  progress: PropTypes.shape({
    amount: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.number,
    color: PropTypes.string,
  }),
};

function ProgressItem({ progress, onClick }) {
  return (
    <Stack spacing={1} sx={{ cursor: "pointer" }} onClick={onClick}>
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
          {progress.label}
        </Typography>
        <Typography variant="subtitle2">{progress.amount} Adet</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          &nbsp;({fPercent(progress.value)})
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={progress.value}
        color={progress.color}
      />
    </Stack>
  );
}

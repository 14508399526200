import { Grid } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import qs from "query-string";
import AllShipmentAcceptances from "./AllShipmentAcceptances";
import StaticsChart from "./StaticsChart";

const Statics = ({ label, data }) => {
  const history = useHistory();
  // subeDagitimTotal
  // kabulTotal
  // iadeTotal

  const allTotal = data.subeDagitimTotal + data.kabulTotal + data.iadeTotal;

  function handleClick({ status, title }) {
    history.push({
      pathname: "/shipments-detail",
      search: qs.stringify({ status, pageTitle: title }),
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={5}>
        <StaticsChart
          data={[data.kabulTotal, data.subeDagitimTotal, data.iadeTotal]}
          labels={["Kabul", "Şube ve Dağıtım", "İade"]}
          title="Paket Durum Grafiği"
        />
      </Grid>
      <Grid item xs={12} lg={7}>
        <AllShipmentAcceptances
          label={label}
          data={[
            {
              label: "Kabul",
              amount: data.kabulTotal,
              value: (data.kabulTotal * 100) / allTotal,
              color: "primary",
              onClick: () => handleClick({ status: "ACCEPTS", title: "Kabul" }),
            },
            {
              label: "Şube ve Dağıtım",
              amount: data.subeDagitimTotal,
              value: (data.subeDagitimTotal * 100) / allTotal,
              color: "warning",
              onClick: () =>
                handleClick({
                  status: "BRANCH_AND_DISTRIBUTIONS",
                  title: "Şube ve Dağıtım",
                }),
            },
            {
              label: "İade",
              amount: data.iadeTotal,
              value: (data.iadeTotal * 100) / allTotal,
              color: "error",
              onClick: () =>
                handleClick({ status: "WILL_BE_RETURN", title: "İade" }),
            },
            {
              label: "Toplam Paket",
              amount: allTotal,
              value: (allTotal * 100) / allTotal,
              color: "secondary",
              onClick: () =>
                handleClick({ status: "ANALYSE_ALL", title: "Toplam Paket" }),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Statics;

import React from "react";
import PropTypes from "prop-types";
import { OverlayView } from "@react-google-maps/api";
import {
  Divider,
  Fade,
  Paper,
  Popper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { LocalShipping } from "@mui/icons-material";
import moment from "moment";

DepoSonArac.propTypes = {
  data: PropTypes.object,
  route: PropTypes.object,
  zIndex: PropTypes.number,
};

export default function DepoSonArac(props) {
  const { data, zIndex, route } = props;
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const fullName = `${route.driver.first_name} ${route.driver.last_name}`;

  return (
    <OverlayView
      zIndex={open ? 11 : zIndex}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      bounds={{
        ne: { lat: data.latitude, lng: data.longitude },
        sw: { lat: data.latitude, lng: data.longitude },
      }}
    >
      <Stack
        onMouseEnter={handleClick()}
        onMouseLeave={() => setOpen(false)}
        sx={{
          zIndex: open ? 11 : zIndex,
          cursor: "pointer",
          width: 50,
          height: 50,
          position: "relative",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          animation: "effectBg 1.5s linear infinite",
          "@keyframes effectBg": {
            "0%": {
              bgcolor: `transparent`,
            },
            "50%": {
              bgcolor: palette.info.main,
            },
            "100%": {
              bgcolor: `transparent`,
            },
          },
        }}
      >
        <Stack
          sx={{
            width: 35,
            height: 35,
            textAlign: "center",
            borderRadius: "50%",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: palette.common.white,
            border: `2px solid ${palette.info.main}`,
          }}
        >
          <LocalShipping
            fontSize="small"
            sx={{ color: `${palette.info.main}!important` }}
          />
          <Popper
            open={open}
            transition
            placement="top"
            anchorEl={anchorEl}
            disablePortal
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{ px: 2, py: 1, minWidth: 250 }}>
                  <Typography variant="subtitle2" flex={1} textAlign="left">
                    Anlık Araç Bilgisi
                  </Typography>
                  <Divider />
                  <Stack spacing={1} pt={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography noWrap variant="caption">
                        Plaka:
                      </Typography>
                      <Typography variant="caption">
                        {route.vehicle.name}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography noWrap variant="caption">
                        Sürücü:
                      </Typography>
                      <Typography variant="caption">{fullName}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography noWrap variant="caption">
                        Hız:
                      </Typography>
                      <Typography variant="caption">{data.speed}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography noWrap variant="caption">
                        Tarih:
                      </Typography>
                      <Typography variant="caption">
                        {moment(data.timestamp).format("DD.MM.YYYY")}
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography noWrap variant="caption">
                        Saat:
                      </Typography>
                      <Typography variant="caption">
                        {moment(data.timestamp).format("HH:mm:ss")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Fade>
            )}
          </Popper>
        </Stack>
      </Stack>
    </OverlayView>
  );
}

import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
// hooks
import useOffSetTop from "hooks/useOffSetTop";
import useResponsive from "hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyles";
// components
// import Logo from "../../../components/Logo";
import Iconify from "components/Iconify";
//
// import Searchbar from './Searchbar';
import AccountPopover from "./AccountPopover";
import { Search } from "@mui/icons-material";
import React from "react";
import SearchBox from "../../Navbar/SearchBox";
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",

  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  isMobile,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const [searchVisible, setSearchVisible] = React.useState(false);

  return (
    <>
      {!isMobile ? (
        <RootStyle
          isCollapse={isCollapse}
          isOffset={isOffset}
          verticalLayout={verticalLayout}
        >
          <Toolbar
            sx={{
              minHeight: "100% !important",
              px: { lg: 5 },
            }}
          >
            <IconButton onClick={() => setSearchVisible(true)}>
              <Search />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <AccountPopover />
            </Stack>
          </Toolbar>
        </RootStyle>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            p: 1,
            m: 1,
            borderRadius: 1,
            cursor: "pointer",
            bgcolor: "#f3f4f7",
          }}
          onClick={() => setSearchVisible(true)}
        >
          <Search />
          <Typography variant="subtitle2">Hızlı sipariş sorgula</Typography>
        </Stack>
      )}
      <SearchBox open={searchVisible} onClose={() => setSearchVisible(false)} />
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";
import {
  alpha,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  styled,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import TableMoreMenu from "components/CustomTable/TableMoreMenu";
import Iconify from "components/Iconify";
import Label from "components/CustomTable/TableLabel";
import { ARAC_TIPLERI } from "helper";
import { TextFirstLetter } from "utils";
import { VehicleEvaluationResult } from "screens/VehicleManagement/VehicleEvaluationResult";

const IconWrapperStyle = styled("div")(({ theme }) => ({
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

Item.propTypes = {
  item: PropTypes.object,
  handleProjectsOpen: PropTypes.func,
};

export default function Item({
  item,
  handleProjectsOpen,
  handleDelete,
  toggleModal,
  handleUpdate,
  handleToggleActivePassive,
}) {
  const { palette } = useTheme();
  const [openMenu, setOpenMenuActions] = React.useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const color = ARAC_TIPLERI[item.aracTip]?.color || "primary";
  const color1 = palette[color].main;
  const bgColor = alpha(palette[color].main, 0.08);

  const isActive = item.status === "ACTIVE";

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TableRow hover sx={{ "& td": { border: 0 } }}>
      <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.plaka)}</Typography>
      </TableCell>
      <TableCell>
        {item.aracTip ? (
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconWrapperStyle sx={{ color: color1, backgroundColor: bgColor }}>
              <Box
                component="img"
                src={ARAC_TIPLERI[item.aracTip]?.icon}
                sx={{ width: 20, height: 20 }}
              />
            </IconWrapperStyle>
            <Typography variant="caption">{item.aracTip}</Typography>
          </Stack>
        ) : null}
      </TableCell>
      <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.model)}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">
          {TextFirstLetter(item.surucu)}
        </Typography>
      </TableCell>
      {/* <TableCell>
        <Typography variant="caption">{TextFirstLetter(item.name)}</Typography>
      </TableCell> */}
      {/* <TableCell title={item.projeler}>
        {item.projeler.length > 0 && (
          <Button
            size="small"
            onClick={() => handleProjectsOpen(item)}
            variant="contained"
          >
            {item.projeler.length > 0 ? "Projeleri Gör" : ""}
          </Button>
        )}
      </TableCell> */}
      <TableCell
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Popover
          sx={{ pointerEvents: "none" }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 2 }} variant="subtitle1">
            Şubeler
          </Typography>
          <Divider />
          <List sx={{ minWidth: "320px" }} dense>
            {item.branches.map((branch, index) => {
              return (
                <ListItem key={index}>
                  <ListItemText primary={`• ${branch.name}`} />
                </ListItem>
              );
            })}
          </List>
        </Popover>
        <Label
          variant="ghost"
          color={item.branchesTotal > 0 ? "info" : "default"}
          sx={{ cursor: item.branchesTotal > 0 ? "pointer" : "auto" }}
        >
          {`${item.branchesTotal} Şube`}
        </Label>
      </TableCell>
      <TableCell>
        <Button
          size="small"
          variant="contained"
          color={isActive ? "primary" : "error"}
          onClick={handleToggleActivePassive}
        >
          {isActive ? "Aktif" : "Pasif"}
        </Button>
      </TableCell>
      <TableCell>
        {item.evaluation_result ===
        VehicleEvaluationResult.WAIT_FOR_EVALUATE ? (
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={async (e) =>
                await handleUpdate(0, item.id, VehicleEvaluationResult.APPROVED)
              }
            >
              {"Onayla"}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={async (e) =>
                await handleUpdate(1, item.id, VehicleEvaluationResult.REJECTED)
              }
            >
              Reddet
            </Button>
          </Stack>
        ) : (
          <Label
            variant="ghost"
            color={
              item.evaluation_result === VehicleEvaluationResult.APPROVED
                ? "success"
                : "error"
            }
          >
            {item.evaluation_result === VehicleEvaluationResult.APPROVED
              ? "Onaylandı"
              : "Reddedildi"}
          </Label>
        )}
      </TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => handleDelete(item.id)}
                sx={{ color: "error.main" }}
              >
                <Iconify icon={"eva:trash-2-outline"} />
                Sil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleModal(item);
                  handleCloseMenu();
                }}
              >
                <Iconify icon={"eva:edit-fill"} />
                Düzenle
              </MenuItem>
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}

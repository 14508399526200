import React from "react";
import merge from "lodash/merge";
// @mui
import { Box, Card, CardHeader } from "@mui/material";
// utils
import { fNumber } from "utils/formatNumber";
// components
import { BaseOptionChart } from "components/chart";

import ReactApexChart from "react-apexcharts";
import { Services } from "api/Services";
import moment from "moment";

// ----------------------------------------------------------------------
const server = new Services();

export default function Branches({ userFilter }) {
  const [data, setData] = React.useState([]);
  //sadece render edilince çalışsın
  React.useEffect(() => {
    userFilter.end_date = moment(userFilter.start_date).format("YYYY-MM-DD");
    server
      .getFilterResult("shipments-by-branch", userFilter)
      .then((res) => {
        setData(res.entity.data);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, [userFilter]);

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => "",
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
    },
    xaxis: {
      categories: data.map((i) => i.name),
    },
  });

  const CHART_DATA = [
    {
      data: data.map((i) => i.count),
    },
  ];

  return (
    <Card>
      <CardHeader title="Şubeler Dağılımı" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}

import {
  Stack,
  Card,
  Avatar,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import Label from "components/Label";

export default function VehicleStatics({ data }) {
  const theme = useTheme();

  const dataNew = [
    {
      id: 0,
      name: "Aktif araç sayısı",
      value: data.workingvehiclecount,
      icon: "/images/customerDashboard/panelvan.svg",
    },
    {
      id: 1,
      name: "Ortalama desi",
      value: Math.ceil(data.avgdecibyvehicle),
      icon: "/images/customerDashboard/deci.svg",
    },
    {
      id: 2,
      name: "Ortalama paket",
      value: Math.ceil(data.avgpackagebyvehicle),
      icon: "/images/customerDashboard/paket.svg",
    },
    {
      id: 3,
      name: "Ortalama sipariş",
      value: Math.ceil(data.avgorderbyvehicle),
      icon: "/images/customerDashboard/siparis.svg",
    },
    {
      id: 4,
      name: "Ortalama rota mesafesi",
      value: (data.avgroutedistancebyvehicle / 1000).toFixed(2) + " km",
      icon: "/images/customerDashboard/rota.svg",
    },
    {
      id: 5,
      name: "Gerçekleşen Rota Mesafesi",
      value:
        ((data.avgcompleteddistancebyvehicle || 0) / 1000).toFixed(2) + " km",
      icon: "/images/customerDashboard/rota.svg", // @TODO icon gelince değiştirilecek.
    },
  ];

  return (
    <Card>
      <CardHeader title="Araç Bazlı İstatistikler" />
      <Stack spacing={3} sx={{ p: 3 }}>
        {dataNew.map((item) => (
          <Stack key={item.id} direction="row" alignItems="center" spacing={2}>
            <Avatar alt={item.name} src={item.icon} />
            <Stack direction={"row"} sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle2" flex={1}>
                {item.name}
              </Typography>
              <Typography variant="caption">
                <Label
                  variant={theme.palette.mode === "light" ? "ghost" : "filled"}
                  color="info"
                >
                  {item.value}
                </Label>
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Card>
  );
}

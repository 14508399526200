import React, { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";

const AuthRoutes = (props) => {
  const { path, isAuthUser, type, component } = props;
  const Component = component;

  if (type === "private" && isAuthUser) {
    return (
      <Route
        {...props}
        render={(props) => (
          <Fragment>
            {/* <Container> */}
            {/* <NavBar/> */}
            {/* <BodyContainer>
               <Body> */}
            <Component {...props} />
            {/* </Body>
             </BodyContainer> */}
            {/* </Container> */}
          </Fragment>
        )}
      />
    );
  }

  return (
    <Redirect to={{ pathname: "/login", state: { prevLocation: path } }} />
  );
};

export default AuthRoutes;

const VehicleEvaluationResult = {
    WAIT_FOR_EVALUATE : "WAIT_FOR_EVALUATE",
    APPROVED : "APPROVED",
    REJECTED : "REJECTED"
}

const VehicleEvaluationResultDecimal = {
    WAIT_FOR_EVALUATE : 0,
    APPROVED : 1,
    REJECTED : 2
}


export { VehicleEvaluationResult, VehicleEvaluationResultDecimal };
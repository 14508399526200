import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, MenuList, Select, Slider, Stack, Switch } from "@mui/material";
import {
  ColorLens,
  LineAxis,
  LocalShipping,
  LocationOn,
  Opacity,
  RouteOutlined,
} from "@mui/icons-material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    sx={{ top: -15 }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MapOptions({ mapOptions, setMapOptions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={{ position: "absolute", left: 0, bottom: 0, zIndex: 2, p: 2 }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        color="info"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowUpIcon />}
      >
        Katmanlar
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple>
          <Stack direction="row" alignItems="center" spacing={2} width={1}>
            <Stack direction="row" alignItems="center" flex={1}>
              <LocationOn />
              Plan Sırası
            </Stack>
            <Switch
              size="small"
              checked={mapOptions.planSirasi}
              onChange={(e) => {
                setMapOptions((s) => ({ ...s, planSirasi: e.target.checked }));
              }}
            />
          </Stack>
        </MenuItem>
        <MenuItem disableRipple sx={{ display: "block" }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" flex={1}>
              <RouteOutlined />
              Planlanan İz Düşümü
            </Stack>
            <Switch
              size="small"
              checked={mapOptions.planlananNoktalar}
              onChange={(e) => {
                setMapOptions((s) => ({
                  ...s,
                  planlananNoktalar: e.target.checked,
                }));
              }}
            />
          </Stack>
          <MenuList>
            <MenuItem>
              <Stack direction="row" alignItems="center" spacing={2} width={1}>
                <Stack direction="row" alignItems="center" flex={1}>
                  <Opacity />
                  Rota Opaklığı
                </Stack>
                <Slider
                  size="medium"
                  value={mapOptions.opacity}
                  valueLabelDisplay="auto"
                  step={10}
                  min={10}
                  sx={{ width: 90 }}
                  onChange={(e, value) => {
                    setMapOptions((s) => ({ ...s, opacity: value }));
                  }}
                />
              </Stack>
            </MenuItem>
            <MenuItem>
              <Stack direction="row" alignItems="center" spacing={2} width={1}>
                <Stack direction="row" alignItems="center" flex={1}>
                  <ColorLens />
                  Rota Rengi
                </Stack>
                <input
                  type="color"
                  value={mapOptions.color}
                  style={{
                    width: 90,
                    height: 22,
                  }}
                  onChange={(e) => {
                    setMapOptions((s) => ({ ...s, color: e.target.value }));
                  }}
                />
              </Stack>
            </MenuItem>
            <MenuItem>
              <Stack direction="row" alignItems="center" spacing={2} width={1}>
                <Stack direction="row" alignItems="center" flex={1}>
                  <LineAxis />
                  Rota Kalınlığı
                </Stack>
                <Select
                  sx={{
                    width: 90,
                    height: 30,
                    display: "flex",
                    alignItems: "center",
                    "& .MuiSelect-select": {
                      height: "max-content",
                      maxHeight: 0,
                      display: "flex",
                      alignItems: "center",
                      mr: 1,
                    },
                  }}
                  value={mapOptions.weight}
                  onChange={(e) => {
                    setMapOptions((s) => ({ ...s, weight: e.target.value }));
                  }}
                >
                  {[4, 6, 8, 12, 16, 20].map((item) => (
                    <MenuItem key={item.toString()} value={item}>
                      <Box
                        sx={{
                          width: 1,
                          height: item,
                          backgroundColor: mapOptions.color,
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </MenuItem>
          </MenuList>
        </MenuItem>
        <MenuItem disableRipple>
          <Stack direction="row" alignItems="center" spacing={2} width={1}>
            <Stack direction="row" alignItems="center" flex={1}>
              <RouteOutlined />
              Gerçekleşen İz Düşümü
            </Stack>
            <Switch
              size="small"
              checked={mapOptions.gerceklesenNoktalar}
              onChange={(e) => {
                setMapOptions((s) => ({
                  ...s,
                  gerceklesenNoktalar: e.target.checked,
                }));
              }}
            />
          </Stack>
        </MenuItem>
        <MenuItem disableRipple>
          <Stack direction="row" alignItems="center" spacing={2} width={1}>
            <Stack direction="row" alignItems="center" flex={1}>
              <LocalShipping />
              GPS Araçlar
            </Stack>
            <Switch
              size="small"
              checked={mapOptions.gpsSonArac}
              onChange={(e) => {
                setMapOptions((s) => ({ ...s, gpsSonArac: e.target.checked }));
              }}
            />
          </Stack>
        </MenuItem>
      </StyledMenu>
    </Stack>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { Card, Stack, Typography } from "@mui/material";
import AnalyticsTotalBox from "./AnalyticsTotalBox";

AnalyticsTotalBoxWrapper.propTypes = {
  title: PropTypes.string,
  bgcolor: PropTypes.string,
  data: PropTypes.array,
  direction: PropTypes.string,
};

export default function AnalyticsTotalBoxWrapper(props) {
  const { title, bgcolor, direction, data } = props;

  return (
    <Card sx={{ p: 2, bgcolor }}>
      <Typography variant="subtitle1" sx={{ textAlign: "center", pb: 2 }}>
        {title}
      </Typography>
      <Stack
        spacing={2}
        direction={{
          xs: "column",
          md: direction ?? "column",
        }}
      >
        {data.map((item, index) => {
          return (
            <AnalyticsTotalBox
              key={`AnalyticsTotalBox-#${index}`}
              item={item}
            />
          );
        })}
      </Stack>
    </Card>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextField } from "@mui/material";

OnlyLetterInput.propTypes = {
  label: PropTypes.string,
};

export default function OnlyLetterInput({ label, ...props }) {
  const [field, meta, helpers] = useField(props);

  const handleChange = (e) => {
    const inputValue = e.target.value;

    const re = /^[A-Za-zÇĞİÖŞÜçğıöşü ]+$/;
    if (inputValue === "" || re.test(inputValue)) {
      helpers.setValue(inputValue);
    }
  };

  return (
    <TextField
      label={label}
      {...field}
      {...props}
      onChange={handleChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}

import React from "react";
import PropTypes from "prop-types";
import NavBar from "../Navbar";
import { Box, Stack, styled, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import HeaderBreadcrumbs from "./Breadcrumbs";
import DashboardHeader from "./header";
import { NAVBAR, HEADER } from "../../config";
import { Search } from "@mui/icons-material";

const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "collapseClick",
})(({ collapseClick, theme }) => ({
  flexGrow: 1,

  [theme.breakpoints.up("lg")]: {
    paddingLeft: 16,
    paddingRight: 16,

    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const Layout = (props) => {
  const { heading, links, action, children } = props;
  const isMobile = useMediaQuery("(max-width:1200px)");
  const navBarWidth = NAVBAR.DASHBOARD_WIDTH;
  return (
    <>
      <NavBar navBarWidth={navBarWidth} isMobile={isMobile} />
      <DashboardHeader isMobile={isMobile} />
      <Box
        mt={!isMobile ? 2 : 0}
        sx={{
          px: 2,
          pt: isMobile ? 0 : `${HEADER.MAIN_DESKTOP_HEIGHT}px`,
          ml: isMobile ? 0 : `${navBarWidth}px`,
          width: isMobile ? "100%" : `calc(100% - ${navBarWidth}px)`,
        }}
      >
        <MainStyle>
          <HeaderBreadcrumbs heading={heading} links={links} action={action} />
          <Box sx={{ pb: 10 }}>{children}</Box>
        </MainStyle>
      </Box>
    </>
  );
};

Layout.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.array,
  action: PropTypes.any,
  children: PropTypes.any,
};

export default Layout;
